import { ComponentType, useEffect, useRef, useState } from "react"
import { FrameProps } from "framer"

const mask = `
    linear-gradient(to right, 
        rgb(0, 0, 0) var(--button-mask-width),
        transparent var(--button-mask-width)
    ) left center / 51% 100% no-repeat,
    linear-gradient(to left,
        rgb(0, 0, 0) var(--button-mask-width),
        transparent var(--button-mask-width)
    ) right center / 51% 100% no-repeat
`

const generateButtonProps = (start, end) => {
    return {
        initial: {
            "--button-mask-width": start,
        },
        whileHover: {
            "--button-mask-width": end,
        },
        transition: {
            duration: 1,
            ease: [0.19, 1, 0.22, 1],
        },
    }
}

export function withButtonMaskFill(Component): ComponentType {
    return (props: FrameProps) => {
        return (
            <Component
                {...props}
                {...generateButtonProps("8px", "100%")}
                style={{
                    ...props.style,
                    WebkitMask: mask,
                    mask,
                }}
            />
        )
    }
}

export function withButtonMaskUnfill(Component): ComponentType {
    return (props: FrameProps) => {
        return (
            <Component
                {...props}
                {...generateButtonProps("100%", "8px")}
                style={{
                    ...props.style,
                    WebkitMask: mask,
                    mask,
                }}
            />
        )
    }
}
